<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :rules="rules"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'市场管理人员'"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="请输入内容"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">姓名</template>
        </el-input>
        <el-input
          clearable
          placeholder="按手机号查询"
          v-model="searchQuery.phone"
          class="searchInput"
        >
          <template slot="prepend">手机</template>
        </el-input>
        <el-input
          clearable
          placeholder="按身份证号查询"
          v-model="searchQuery.idCard"
          class="searchInput"
        >
          <template slot="prepend">身份证</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      remote: api.common,
      apiName: "/market/personnel",
      searchQuery: { name: "", idCard: "", phone: "" },
      jobList: [],
      rules: {
        //表单验证
        name: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("名字不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("手机号不能为空"));
              } else {
                if (!this.$test.mobile(value)) {
                  callback(new Error("手机号格式不正确"));
                } else {
                  callback();
                }
              }
            },
            required: true,
            trigger: ["blur","change"],
          },
        ],
        idCard: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入身份证"));
              } else if (!this.$test.idCard(value)) {
                callback(new Error("身份证号格式有误"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur","change"],
          },
        ],
        jobId: [
          { required: true, message: "请选择职位", trigger: "change" },
        ],
        enterDate: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("入职时间不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur","change"],
          },
        ],
        address: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("地址不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur","change"],
          },
        ],
      },

      props: [
        {
          label: "姓名",
          prop: "name",
          align: "center",
        },

        {
          label: "电话",
          prop: "phone",
          align: "center",
        },
        {
          label: "性别",
          prop: "sex",
          align: "center",
          formatter: function (row) {
            const statusMap = { 0: "未知", 1: "男", 2: "女" };
            return statusMap[row.sex];
          },
        },

        {
          label: "身份证号",
          prop: "idCard",
          align: "center",
        },
        {
          label: "职位",
          prop: "jobName",
          align: "center",
        },

        {
          label: "入职时间",
          prop: "enterDate",
          align: "center",
        },
        {
          label: "家庭住址",
          prop: "address",
          align: "center",
          width: "400px",
        },
      ],
      formProps: [
        {
          label: "姓名",
          prop: "name",
          type: "input",
        },

        {
          label: "电话",
          prop: "phone",
          type: "input",
        },

        {
          label: "身份证号",
          prop: "idCard",
          type: "input",
        },
        {
          label: "职位",
          prop: "jobId",
          type: "select",
          selectData: [],
        },
        {
          label: "入职时间",
          prop: "enterDate",
          type: "date",
        },
        {
          label: "家庭住址",
          prop: "address",
          type: "input",
        },
      ],
    };
  },
  created() {
    this.getJobList();
  },
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "职位" }).then((res) => {
        this.formProps[3].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 20%;
  margin-right: 20px;
}
</style>
